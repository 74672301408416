<template>
  <van-skeleton v-if="loading" :row="6" />
  <div v-else class="page">
    <div id="user_info" v-if="niming" >
      <img class="avatar" src="https://www.ssqm.cn/static/img/avatar/default.png" data-value="zc" @click="pull_up" />
      <span class="nickname" data-value="zc" @click="pull_up">{{user_info.nickname}} </span>
      <span class="role" data-value="zc" @click="pull_up" >登录</span>
    </div>
    <div id="user_info" v-else>
      <img class="avatar" src="https://www.ssqm.cn/static/img/avatar/default.png" />
      <span class="nickname">{{user_info.nickname}} </span>
      <!-- <span v-if="!user_info.has_phone" class="role orange" data-value="zc" @click="pull_up" >绑定手机号</span> -->
      <span  class="role" data-value="vip_detail"  @click="pull_up"  :class="user_info.role==='登录' || user_info.role==='普通用户' ? 'light':'orange'"  >{{ user_info.role }}</span>
    </div>
    <p class='tips' v-if="user_info.vip_info.is_vip" >您是会员，距离到期时间还有{{user_info.vip_info.have_days}}天</p>
    <div class="mld">
      <div class="card"  data-value="name_mark" @click="xml_go_to"  >
        <van-icon name="like-o" size="0.72rem" />
        <p class="desc">收藏名字</p>
      </div>
      <div class="card" data-value="group_exam" @click="xml_go_to_only" >
        <van-icon name="sign" size="0.72rem" />
        <p class="desc">盲区测验</p>
      </div>
    </div>
    <div class="mld">
      <div class="card" data-value="mine_index|" @click="xml_go_to_goods_index" >
        <van-icon name="diamond-o" size="0.72rem" />
        <p class="desc">成为会员</p>
      </div>
      <div class="card"  data-value="orders" @click="xml_go_to" >
        <van-icon name="shopping-cart-o" size="0.72rem" />
        <p class="desc">订单记录</p>
      </div>
      <div class="card"  data-value="order_find" @click="xml_go_to_only" >
        <van-icon name="search" size="0.72rem" />
        <p class="desc">找回订单</p>
      </div>
    </div>
    <div class="mld">
      <div class="card"  data-value="bind_tips" @click="xml_go_to" >
        <van-icon name="cluster-o" size="0.72rem" />
        <p class="desc">绑定账号</p>
      </div>
    </div>
    <div class="mld">
      <div class="card"  data-value="group_about" @click="xml_go_to_only" >
        <van-icon name="contact" size="0.72rem" />
        <p class="desc">关于我们</p>
      </div>
      <div class="card" data-value="group_qa" @click="xml_go_to" >
        <van-icon name="friends-o" size="0.72rem" />
        <p class="desc">起名问答</p>
      </div>
      <div class="card"  data-value="contact" @click="pull_up" >
        <van-icon name="phone-o" size="0.72rem" />
        <p class="desc">联系客服</p>
      </div>
    </div>

    <p v-if="user_info.vip_info.is_vip && user_info.has_phone" data-value="bind_tips" @click="xml_go_to">下载花样起名APP，手机号登录体验更佳。</p>
    <p v-if="user_info.vip_info.is_vip && !user_info.has_phone" data-value="bind_tips" @click="xml_go_to">网页绑定手机号，下载花样起名APP体验更佳。</p>
    <button v-if="!niming" @click="login_out" class="in_body lightgray">退出账号</button>

    <ul class="home_footer">
      <li class="icons"><router-link :to="{name:'index'}">首页</router-link></li>
      <li class="icons active_icons un_index"><router-link :to="{name:'mine_index'}">我的</router-link></li>
    </ul>
    <van-popup v-model:show="show.contact" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Contact></Contact>
    </van-popup>

    <van-popup v-model:show="show.zc" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <Login></Login>
    </van-popup>

    <van-popup v-model:show="show.vip_detail" round position="bottom" :style="{ height: '72%' }" @close="on_close">
      <div class="mod">
        <span>您是{{user_info.role}}</span>
        <span v-if="user_info.vip_info.is_vip" >， 距离到期时间还有{{user_info.vip_info.have_days}}天</span>
        <span v-else>，成为会员可顺畅浏览 诗词、成语谐音起名，配字起名等功能</span>
      </div>
    </van-popup>

  </div>
</template>

<script>

import store from '@/store'
import axios from 'axios'
import Utils from '@/utils/utils'
import { Dialog } from 'vant'
import Login from '@/components/other/Login'
import Contact from '@/components/other/Contact'

export default {
  name: 'mine_index',
  store,
  components: {
    Login, Contact
  },
  data () {
    return {
      loading: false,
      show: { zc: false, contact: false, vip_detail: false },
      niming: store.state.niming,
      user_info: {
        nickname: '匿名',
        role: '登录',
        sex: 0,
        avatar: 'https://thirdwx.qlogo.cn/mmopen/vi_32/CeEFXxUWaqP87hzfffgHPicVNjRo3XOfUBLrnY8VvicGibJaUhN4AfCeX7cib2Cq4rzqUNmZKyk4EZbUofBxoGzvZQ/132',
        vip_info: {}
      }
    }
  },
  mounted () {
    this.mine_index()
  },
  methods: {
    mine_index () {
      if (!this.niming) {
        axios.post('/mine_index/', {})
          .then(res => {
            if (res.data.code !== 200) {
              Utils.alert({ content: res.data.msg })
              return
            }
            this.user_info = res.data.data.user_info
            this.loading = false
          })
      } else {
        axios.post('/log_sth_unlogin/', { tag: 'mine_index' })
          .then(res => {
            this.loading = false
          })
      }
    },
    login_out (e) {
      let msg = ''
      if (this.user_info.has_phone) {
        msg = '确定退出？'
      } else {
        msg = '未绑定手机号，账号信息将不保留'
      }
      Dialog.confirm({
        message: msg,
        confirmButtonText: '朕知道了',
        confirmButtonColor: '#4fc08d'
      })
        .then(() => {
          axios.post('/login_out/', {})
            .then(res => {
              if (res.data.code !== 200) {
                Utils.alert({ content: res.data.msg })
                return
              }
              localStorage.removeItem('token')
              this.niming = true
              store.commit('is_niming')
              location.href = store.state.base_url
            })
        })
    },
    xml_go_to (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to(e)
    },
    xml_go_to_goods_index (e) {
      if (!localStorage.getItem('token')) {
        this.show.zc = true
        return
      }
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    on_close () {
      this.show = { zc: false, contact: false, vip_detail: false }
    }
  }
}
</script>

<style scoped>
#user_info {
  margin-top:0.6rem;
  padding: 0.6rem 0.6rem 0.6rem 0.6rem;
  background: #fefefe;
  border: none;
  text-align: left;
  vertical-align: middle;
}
#user_info img, #user_info span{
  height: 1.2rem;
  line-height: 1.2rem;
  vertical-align: middle;
  display: inline-block;
}

#user_info img {
  width: 1.2rem;
  margin-right: 0.20rem;
  margin-left: 0.30rem;
}

#user_info .role{
  float: right;
}

.mld{
  margin: 0.40rem 0rem;
  text-align: left;
  margin: 0.4rem 0rem ;
  padding: 0.1rem 0.4rem;
  background-color: #fefefe;

}
.mld div.card{
  display: inline-block;
}
.card {
  width: 1.45rem;
  padding:  0.32rem 0.2rem;
  margin:  0.40rem 0.2rem;
  text-align: center;
  vertical-align: middle;
}

.card .desc{
  margin-top: 0.1rem;
  font-size: 0.32rem;
}
</style>
